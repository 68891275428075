import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "../utils/array-util"

const LinkMap = {
  "Option Selector": "39b495f1c74",
  "Percent Stacked Bar": "a8a9e02212c7",
  "Load Lazy": "7bb39ff7d773",
  "Chrome Extension": "f6087bc6db4b",

  "webkit-scrollbar-track": "79cc3472e503",
  "webkit-scrollbar": "79cc3472e503",
  "webkit-scrollbar-thumb": "79cc3472e503",
  handleScroll: "79cc3472e503",

  "Design Extensible": "1c491e9ed525",

  "Infinite Scrolling": "6565c775aef6",
  "React Application Architecture": "1c491e9ed525",

  "Files-Loader": "63b4667accf5",
  "JSON-Loader": "63b4667accf5",
  Transpiling: "63b4667accf5",
  Templating: "63b4667accf5",
  Styling: "63b4667accf5",
  "Linting and Testing": "63b4667accf5",
  Frameworks: "63b4667accf5",
}

const storiesJSPlainEnglish = [
  {
    title: "Design Extensible React Application Architecture",
    postId: "1c491e9ed525",
  },
  {
    title: "Make React Apps More Dynamic",
    postId: "49c83944f6de",
  },
]

const storiesTechniques = [
  {
    title: "Design Extensible React Application Architecture",
    postId: "1c491e9ed525",
  },
  {
    title: "How to Write Your Own Option Selector",
    postId: "39b495f1c74",
  },
  {
    title: "How to Write Your Own Percent Stacked Bar",
    postId: "a8a9e02212c7",
  },
  {
    title: "Load Lazy When Routing",
    postId: "7bb39ff7d773",
  },
  {
    title: "How to Hide Scrollbar and Visible Only Scrolling",
    postId: "79cc3472e503",
  },
  {
    title: "How to Write an Infinite Scroll List with React",
    postId: "6565c775aef6",
  },
  {
    title: "Why Web Dev Preprocessors are So Important?",
    postId: "63b4667accf5",
  },
]

const knowledgeBase = [
  {
    title: "1.GitHub Repo Explorer (Knowledge Map)",
    postId: "891c92a2a65c",
  },
  {
    title: "2.Knowledge Map (Bilgi Haritaları) arasında Bağlantı",
    postId: "d6a4d7dfb0dd",
  },
  {
    title: "3.Digital Garden’ın Yeni Yapısı",
    postId: "4cdf583a557",
  },
  {
    title: "4.Digital Garden’ın Responsive Tasarımı",
    postId: "02f07f2da6f5",
  },
  {
    title: "5.Refine.dev ile Karşılaştırma",
    postId: "02029caf6d7a",
  },
  {
    title: "6.Enterprise React için Altyapı",
    postId: "7c181f094b7a",
  },
  {
    title: "7.Enterprise React için Altyapı-2",
    postId: "70ab63bb3773",
  },
  {
    title: "8.Enterprise React için Altyapı-3 (CRA -> Vite)",
    postId: "954efbdca815",
  },
  {
    title: "9.Enterprise React için Altyapı-4",
    postId: "9b8a7a5fxacc5",
  },
  {
    title: "9.Enterprise React için Altyapı-5",
    postId: "ec3e32c1e5a8",
  },
  {
    title: "10.Enterprise React için Altyapı-6",
    postId: "5c1fcaabd2cb",
  },
  {
    title: "11.Enterprise React için Altyapı-7",
    postId: "564aeac3d221",
  },
]

const storiesGroup = [
  {
    title: "Knowledge Base",
    storyPart: knowledgeBase,
  },
  {
    title: "JS in Plain English",
    storyPart: storiesJSPlainEnglish,
  },
  {
    title: "Techniques",
    storyPart: storiesTechniques,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "React Lab",
  path: "react-lab",
}

const ReactLabPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default ReactLabPage
